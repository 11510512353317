import cx from 'classnames';
import { createElement } from 'react';

import { BrandLink } from '@dx-ui/osc-brand-buttons';
import { Link } from '@dx-ui/osc-link';
import { useTheme } from '../../hooks/use-theme';
import { useGlobalConfig } from '../../hooks/use-global-config';

export type InformationTileProps = {
  children: React.ReactNode;
  title: string;
  ctaType?: 'button' | 'link';
  shouldInlineOnLg?: boolean;
  label?: string;
  url?: string;
  icon?: JSX.Element;
  headingLevel?: 2 | 3;
};

const Heading = ({
  level,
  children,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement> & {
  level: NonNullable<InformationTileProps['headingLevel']>;
}) => {
  const headingLevel = `h${level}` as const;

  return createElement(headingLevel, props, children);
};

export const InformationTile = ({
  children,
  ctaType,
  shouldInlineOnLg,
  label,
  url,
  icon,
  title,
  headingLevel = 2,
}: InformationTileProps) => {
  const { theme } = useTheme();
  const { isTailored } = useGlobalConfig();

  return (
    <div
      className={cx('border-border flex size-full flex-row items-start rounded-lg border p-4', {
        'border-border-alt': !isTailored,
      })}
      data-testid="InformationTile"
      tabIndex={0}
    >
      {icon && (
        <span data-testid="icon" className={cx('pr-2 pt-1', { 'accent-icon': theme.accent?.icon })}>
          {icon}
        </span>
      )}
      <div
        className={cx('flex flex-col', {
          'justify-between lg:w-full lg:flex-row': shouldInlineOnLg,
        })}
      >
        <div className="mb-2 mt-1">
          <Heading
            level={headingLevel}
            className={cx('heading-lg lg:heading-xl', { 'accent-heading': theme.accent?.heading })}
          >
            {title}
          </Heading>
          <div className="pt-2 text-base leading-5 lg:pt-0">{children}</div>
        </div>
        {ctaType && url && (
          <div
            className={cx('pt-4 lg:pt-0', {
              'lg:self-center': shouldInlineOnLg,
            })}
          >
            {ctaType === 'button' && (
              <div className="flex w-full">
                <BrandLink
                  role="button"
                  label={label}
                  url={url}
                  underline={false}
                  isNewWindow
                  showNewWindowIcon={false}
                >
                  {label}
                </BrandLink>
              </div>
            )}
            {ctaType === 'link' && (
              <div className="flex w-full">
                <Link
                  role="link"
                  adaDescription={label}
                  label={label}
                  url={url}
                  isNewWindow
                  className="text-accent"
                >
                  {label}
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
