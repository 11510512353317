import cx from 'classnames';

import { InformationTile } from '../information-tile';
import { useTheme } from '../../hooks/use-theme';
import { ChildComponentThemeProvider } from '../../helpers/providers/theme/theme-provider';

import type { InformationTileProps } from '../information-tile';

type InformationStripProps = {
  tiles: InformationTileProps[];
  headingLevel?: InformationTileProps['headingLevel'];
};

export const InformationStrip = ({ tiles, headingLevel }: InformationStripProps) => {
  const { theme } = useTheme();

  return (
    <div className="container" data-testid="InformationStrip">
      {tiles?.length <= 6 && tiles?.length > 0 && (
        <ul className="flex flex-row flex-wrap gap-4 pb-6 pt-4">
          {tiles.map((card) => (
            <li
              key={card.title}
              className={cx(
                'w-full min-w-[328px] flex-1 snap-start snap-always rounded-lg',
                theme.childTheme?.base
              )}
            >
              <ChildComponentThemeProvider theme={theme.childTheme!}>
                <InformationTile
                  ctaType={card.ctaType}
                  icon={card.icon}
                  label={card.label}
                  title={card.title}
                  url={card.url}
                  headingLevel={headingLevel}
                >
                  {card.children}
                </InformationTile>
              </ChildComponentThemeProvider>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
